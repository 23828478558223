import {
  USERS,
  PERMISSIONS,
  ROLES,
  COMPONENT,
  MINISTRIES,
  STATE_MASTER,
  DISTRICT_MASTER,
  ULB_MASTER,
  BLOCK_MASTER,
  EVENTS,
  MINISTRY_UNIT_MASTER,
  MINISTRY_DEPARTMENT_MASTER,
  USERS_DETAILS,
  EVENT_MEDIA,
  Event_CATEGORY,
  EVENT_TYPE,
  CITY_REPORTS,
  EVENT_THEME_MASTER,
  EVENT_HEADING_MASTER,
  EVENT_NAME_MASTER,
  BLOCKS_GP_MASTER,
  GP_VILLAGE_MASTER,
  SBM,
  TWEETS,
  CITIZEN_TESTIMONIAL,
  INSTAGRAM,
} from './collectionConstants';
import firebase, { db } from './index';
import {
  collection,
  getDocs,
  where,
  query,
  getDoc,
  doc,
  limit,
  startAfter,
  getCountFromServer,
  orderBy,
} from 'firebase/firestore';

export const getCurrentToken = async () => {
  return await firebase.auth().currentUser.getIdToken(true);
};

export const getUserByID = async (id) => {
  const querySnapshot = await getDocs(query(collection(db, USERS), where('user_id', '==', id)));
  let userRecord;
  querySnapshot.forEach((doc) => {
    userRecord = doc.data();
    userRecord.doc_id = doc.id;
  });
  return userRecord;
};

export const getUserByUsername = async (id) => {
  const querySnapshot = await getDocs(
    query(collection(db, USERS_DETAILS), where('user_name', '==', id)),
  );
  let userRecord;
  querySnapshot.forEach((doc) => {
    userRecord = doc.data();
    userRecord.doc_id = doc.id;
  });
  return userRecord;
};

export const getPermissionsByRoleID = async (roleID) => {
  const querySnapshot1 = await getDocs(
    query(collection(db, PERMISSIONS), where('role_id', '==', roleID)),
  );
  let data;
  querySnapshot1.forEach((doc) => {
    data = doc.data();
    data.doc_id = doc.id;
  });
  return data;
};

export const getAllRoles = async () => {
  const querySnapshot = await getDocs(collection(db, ROLES));
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getRoleByName = async (name) => {
  const querySnapshot = await getDocs(query(collection(db, ROLES), where('role_name', '==', name)));
  let data;
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data = newdata;
  });
  return data;
};

export const getAllCityReports = async () => {
  const querySnapshot = await getDocs(collection(db, CITY_REPORTS));
  let report = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    report.push(newdata);
  });
  return report;
};

export const getAllComponents = async () => {
  const querySnapshot = await getDocs(collection(db, COMPONENT));
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getAllUsers = async (lastDoc, pageSize) => {
  let usersSnapshot = query(collection(db, USERS_DETAILS), limit(pageSize));

  if (lastDoc) {
    usersSnapshot = query(collection(db, USERS_DETAILS), startAfter(lastDoc), limit(pageSize));
  }

  const querySnapshot = await getDocs(usersSnapshot);
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });

  return { data, lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1] };
};

export const getAllUsersCount = async () => {
  const countSnapshot = await getCountFromServer(collection(db, USERS));
  const totalUsersCount = countSnapshot.data().count;

  return totalUsersCount;
};

export const getSBMCount = async () => {
  const countSnapshot = await getCountFromServer(collection(db, SBM));
  const totalUsersCount = countSnapshot.data().count;

  return totalUsersCount;
};

export const getFilterCount = async (filterConditions) => {
  let countQuery = collection(db, USERS);
  filterConditions.forEach((condition) => {
    countQuery = query(countQuery, where(condition.field, condition.operator, condition.value));
  });

  const countSnapshot = await getCountFromServer(countQuery);
  const totalUsersCount = countSnapshot.data().count;
  return totalUsersCount;
};

export const getEventFilterCount = async (filterConditions) => {
  let countQuery = collection(db, EVENTS);
  filterConditions.forEach((condition) => {
    countQuery = query(countQuery, where(condition.field, condition.operator, condition.value));
  });

  const countSnapshot = await getCountFromServer(countQuery);
  const totalEvents = countSnapshot.data().count;
  return totalEvents;
};

export const getAllUsersByKey = async (lastDoc, pageSize, key, value) => {
  let querySnapshot = await getDocs(
    query(collection(db, USERS_DETAILS), where(key, '==', value), limit(pageSize)),
  );

  if (lastDoc) {
    await getDocs(
      query(
        collection(db, USERS_DETAILS),
        where(key, '==', value),
        startAfter(lastDoc),
        limit(pageSize),
      ),
    );
  }

  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });

  return { data, lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1] };
};

export const getAllMinistries = async () => {
  const querySnapshot = await getDocs(collection(db, MINISTRIES));
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getAllSBMList = async () => {
  const querySnapshot = await getDocs(collection(db, SBM));
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getAllTestimonialList = async () => {
  const querySnapshot = await getDocs(collection(db, CITIZEN_TESTIMONIAL));
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getSBMListByEmail = async (email) => {
  const q = query(collection(db, SBM), where('created_by_email', '==', email));

  const querySnapshot = await getDocs(q);
  let data = [];

  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getTestimonialListByEmail = async (email) => {
  const q = query(collection(db, CITIZEN_TESTIMONIAL), where('created_by_email', '==', email));

  const querySnapshot = await getDocs(q);
  let data = [];

  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getEventMediaBySBMId = async (sbm_id) => {
  const q = query(collection(db, EVENT_MEDIA), where('sbm_id', '==', sbm_id));
  const querySnapshot = await getDocs(q);
  let data = [];

  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

// export const getAllEventCategories = async () => {
//   const querySnapshot = await getDocs(collection(db, Event_CATEGORY));
//   let data = [];
//   querySnapshot.forEach((doc) => {
//     let newdata = doc.data();
//     newdata.doc_id = doc.id;
//     data.push(newdata);
//   });
//   return data;
// };

export const getAllEventTheme = async () => {
  const querySnapshot = await getDocs(collection(db, EVENT_THEME_MASTER));
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getAllEventHeadings = async (themeId) => {
  const querySnapshot = await getDocs(
    query(collection(db, EVENT_HEADING_MASTER), where('event_theme_id', '==', themeId)),
  );
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getAllEventName = async (headingId) => {
  const querySnapshot = await getDocs(
    query(collection(db, EVENT_NAME_MASTER), where('event_heading_id', '==', headingId)),
  );
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getAllStates = async () => {
  const querySnapshot = await getDocs(collection(db, STATE_MASTER));
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getDistrictsByStateCode = async (stateCode) => {
  const querySnapshot = await getDocs(
    query(collection(db, DISTRICT_MASTER), where('state_code', '==', stateCode)),
  );
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getMinistryDepartmentsByMinistryCode = async (ministryCode) => {
  const querySnapshot = await getDocs(
    query(collection(db, MINISTRY_DEPARTMENT_MASTER), where('ministry_code', '==', ministryCode)),
  );
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getMinistrySubUnitByMinistryDepartmentCode = async (departmentCode) => {
  const querySnapshot = await getDocs(
    query(
      collection(db, MINISTRY_UNIT_MASTER),
      where('ministry_department_code', '==', departmentCode),
    ),
  );
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getULBsByDistrictCode = async (districtCode) => {
  const querySnapshot = await getDocs(
    query(collection(db, ULB_MASTER), where('district_code', '==', districtCode)),
  );
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getBlocksByDistrictCode = async (districtCode) => {
  const querySnapshot = await getDocs(
    query(collection(db, BLOCK_MASTER), where('district_code', '==', districtCode)),
  );
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getGPsByBlockCode = async (blockCode) => {
  const querySnapshot = await getDocs(
    query(collection(db, BLOCKS_GP_MASTER), where('block_code', '==', blockCode)),
  );
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getVillagesByGPCode = async (gpCode) => {
  const querySnapshot = await getDocs(
    query(collection(db, GP_VILLAGE_MASTER), where('gp_code', '==', gpCode)),
  );
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getUserByUserName = async (userName) => {
  const querySnapshot = await getDocs(
    query(collection(db, USERS_DETAILS), where('user_name', '==', userName)),
  );
  const data = [];
  querySnapshot.docs.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getUserByUserId = async (id) => {
  const querySnapshot = await getDocs(query(collection(db, USERS), where('user_name', '==', id)));
  let data;
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data = newdata;
  });
  return data;
};

export const getAllEvents = async (lastDoc = null, pageSize = 1) => {
  let eventQuery = query(collection(db, EVENTS), limit(pageSize));

  if (lastDoc) {
    eventQuery = query(collection(db, EVENTS), startAfter(lastDoc), limit(pageSize));
  }

  const querySnapshot = await getDocs(eventQuery);
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });

  return { data, lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1] };
};

export const getAllEventsByKey = async (lastDoc = null, pageSize = 1, key, value) => {
  let eventQuery = query(collection(db, EVENTS), where(key, '==', value), limit(pageSize));

  if (lastDoc) {
    eventQuery = query(
      collection(db, EVENTS),
      where(key, '==', value),
      startAfter(lastDoc),
      limit(pageSize),
    );
  }

  const querySnapshot = await getDocs(eventQuery);
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });

  return { data, lastDoc: querySnapshot.docs[querySnapshot?.docs?.length - 1] };
};

export const getCollectionMedia = async (lastDoc = null, pageSize = 100) => {
  let eventQuery = query(
    collection(db, 'landing_page_images_ministry_wise'),
    where('ministry_unit_code', '==', 159001000),
    limit(pageSize),
  );

  if (lastDoc) {
    eventQuery = query(
      collection(db, 'landing_page_images_ministry_wise'),
      where('ministry_unit_code', '==', 159001000),
      startAfter(lastDoc),
      limit(pageSize),
    );
  }

  const querySnapshot = await getDocs(eventQuery);
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });

  return { data, lastDoc: querySnapshot.docs[querySnapshot?.docs?.length - 1] };
};

export const getAllAdoptedEvents = async (userId) => {
  let eventQuery = query(
    collection(db, EVENTS),
    where('status', '==', 'APPROVED'),
    where('event_type', '==', 'PARTNER_ADOPT_CTU'),
    where('user_id', '==', userId),
  );

  const querySnapshot = await getDocs(eventQuery);
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });

  return data;
};

export const getAllEventsByQuery = async (lastDoc = null, pageSize = 1, queryParams = {}) => {
  let eventQuery = collection(db, EVENTS);

  for (let key in queryParams) {
    const value = queryParams[key];
    console.log(key, value);
    if (key && value) {
      eventQuery = query(eventQuery, where(key, '==', value));
    }
  }

  if (lastDoc) {
    eventQuery = query(eventQuery, startAfter(lastDoc), limit(pageSize));
  } else {
    eventQuery = query(eventQuery, limit(pageSize));
  }

  const querySnapshot = await getDocs(eventQuery);

  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });

  // Return the data and the last document for pagination
  return { data, lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1] };
};

export const getEventsBySearch = async (userId, key, value) => {
  let eventQuery;
  if (!key) {
    eventQuery = query(collection(db, EVENTS), where('creator_uid', '==', userId));
  } else {
    eventQuery = query(
      collection(db, EVENTS),
      where('creator_uid', '==', userId),
      where(key, '==', value),
    );
  }

  const querySnapshot = await getDocs(eventQuery);
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });

  return data;
};

export const getEventsByDistrictCodeAndEventNameID = async (
  districtCode,
  eventNameID,
  lastDoc = null,
  pageSize = 1,
) => {
  let eventQuery = query(
    collection(db, EVENTS),
    where('district_code', '==', districtCode),
    where('event_name_id', '==', eventNameID),
    limit(pageSize),
  );

  if (lastDoc) {
    eventQuery = query(
      collection(db, EVENTS),
      where('district_code', '==', districtCode),
      where('event_name_id', '==', eventNameID),
      startAfter(lastDoc),
      limit(pageSize),
    );
  }

  const querySnapshot = await getDocs(eventQuery);
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });

  return { data, lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1] };
};

export const getAllEventsToApprove = async (lastDoc = null, pageSize = 1, email) => {
  let eventQuery = query(
    collection(db, 'join_adopt_ctu'),
    where('status', '==', 'REQUESTED'),
    where('creator_email', '==', email),
    limit(pageSize),
  );

  if (lastDoc) {
    eventQuery = query(
      collection(db, 'join_adopt_ctu'),
      where('status', '==', 'REQUESTED'),
      where('creator_email', '==', email),
      startAfter(lastDoc),
      limit(pageSize),
    );
  }

  const querySnapshot = await getDocs(eventQuery);
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });

  return { data, lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1] };
};

export const getAllEventsToApproveForAdmin = async (lastDoc = null, pageSize = 1) => {
  let eventQuery = query(
    collection(db, 'join_adopt_ctu'),
    where('status', '==', 'REQUESTED'),
    limit(pageSize),
  );

  if (lastDoc) {
    eventQuery = query(
      collection(db, 'join_adopt_ctu'),
      where('status', '==', 'REQUESTED'),
      startAfter(lastDoc),
      limit(pageSize),
    );
  }

  const querySnapshot = await getDocs(eventQuery);
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });

  return { data, lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1] };
};

export const getJoinAdoptRequestByOrganisationPhone = async (phone) => {
  const q = query(collection(db, 'join_adopt_ctu'), where('organisation_phone', '==', phone));
  const querySnapshot = await getDocs(q);
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getApprovedPartnerRequestByOrganisationPhone = async (phone) => {
  const q = query(
    collection(db, USERS_DETAILS),
    where('organisation_phone', '==', phone),
    where('isPartnerUserApproved', '==', true),
  );
  const querySnapshot = await getDocs(q);
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getPartnerRequestByOrganisationPhone = async (phone) => {
  const q = query(
    collection(db, USERS_DETAILS),
    where('organisation_phone', '==', phone),
    where('isPartnerUserApproved', '==', false),
  );
  const querySnapshot = await getDocs(q);
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getRequestNumberForOwnedCTU = async (email) => {
  const countQuery = query(
    collection(db, 'join_adopt_ctu'),
    where('status', '==', 'REQUESTED'),
    where('creator_email', '==', email),
  );
  const countSnapshot = await getCountFromServer(countQuery);
  const totalCount = countSnapshot.data().count;
  return totalCount;
};

export const getAllRequestNumber = async (email) => {
  const countQuery = query(collection(db, 'join_adopt_ctu'), where('status', '==', 'REQUESTED'));
  const countSnapshot = await getCountFromServer(countQuery);
  const totalCount = countSnapshot.data().count;
  return totalCount;
};

export const getRuralEventsToApprove = async (lastDoc = null, pageSize = 1, districtCode) => {
  let eventQuery = query(
    collection(db, 'join_adopt_ctu'),
    where('status', '==', 'REQUESTED'),
    where('district_code', '==', districtCode),
    where('location_type', '==', 'RURAL'),
    limit(pageSize),
  );

  if (lastDoc) {
    eventQuery = query(
      collection(db, 'join_adopt_ctu'),
      where('status', '==', 'REQUESTED'),
      where('district_code', '==', districtCode),
      where('location_type', '==', 'RURAL'),
      startAfter(lastDoc),
      limit(pageSize),
    );
  }

  const querySnapshot = await getDocs(eventQuery);
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return { data, lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1] };
};

export const getUrbanEventsToApprove = async (lastDoc = null, pageSize = 1, ulbCode) => {
  let eventQuery = query(
    collection(db, 'join_adopt_ctu'),
    where('status', '==', 'REQUESTED'),
    where('ulb_code', '==', ulbCode),
    where('location_type', '==', 'URBAN'),
    limit(pageSize),
  );

  if (lastDoc) {
    eventQuery = query(
      collection(db, 'join_adopt_ctu'),
      where('status', '==', 'REQUESTED'),
      where('ulb_code', '==', ulbCode),
      where('location_type', '==', 'URBAN'),
      startAfter(lastDoc),
      limit(pageSize),
    );
  }

  const querySnapshot = await getDocs(eventQuery);
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });

  console.log(data);
  return { data, lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1] };
};

export const getRequestNumberForUlbCTU = async (ulbCode) => {
  const countQuery = query(
    collection(db, 'join_adopt_ctu'),
    where('status', '==', 'REQUESTED'),
    where('ulb_code', '==', ulbCode),
    where('location_type', '==', 'URBAN'),
  );

  // Fetch the total count using getCountFromServer
  const countSnapshot = await getCountFromServer(countQuery);
  const totalCount = countSnapshot.data().count;
  return totalCount;
};

export const getRequestNumberForDistrictCTU = async (districtCode) => {
  const countQuery = query(
    collection(db, 'join_adopt_ctu'),
    where('status', '==', 'REQUESTED'),
    where('district_code', '==', districtCode),
    where('location_type', '==', 'RURAL'),
  );

  // Fetch the total count using getCountFromServer
  const countSnapshot = await getCountFromServer(countQuery);
  const totalCount = countSnapshot.data().count;
  return totalCount;
};

export const getAllEventsByEventType = async (lastDoc = null, pageSize = 1) => {
  let eventQuery = query(
    collection(db, EVENTS),
    where('event_type', '!=', null),
    where('status', '==', 'REQUESTED'),
    limit(pageSize),
  );

  if (lastDoc) {
    eventQuery = query(
      collection(db, EVENTS),
      where('event_type', '!=', null),
      where('status', '==', 'REQUESTED'),
      startAfter(lastDoc),
      limit(pageSize),
    );
  }

  const querySnapshot = await getDocs(eventQuery);
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });

  return { data, lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1] };
};

export const getAllEventsByRoleID = async (roleID, lastDoc = null, pageSize = 1) => {
  let eventQuery = query(
    collection(db, EVENTS),
    where('role_id', '==', roleID),
    where('approved', '==', false),
    limit(pageSize),
  );

  if (lastDoc) {
    eventQuery = query(
      collection(db, EVENTS),
      where('role_id', '==', roleID),
      where('approved', '==', false),
      startAfter(lastDoc),
      limit(pageSize),
    );
  }

  const querySnapshot = await getDocs(eventQuery);
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });

  return { data, lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1] };
};

export const getUserDetailsByUserID = async (id) => {
  const usersSnapshot = await getDocs(
    query(collection(db, USERS_DETAILS), where('user_id', '==', id)),
  );
  let userData = [];
  usersSnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    userData.push(newdata);
  });
  return userData;
};

export const getEventByID = async (id) => {
  const docRef = doc(db, EVENTS, id);
  const docSnap = await getDoc(docRef);
  return docSnap.data();
};

export const getMediaImages = async (collectionName) => {
  // Create a query with ordering by image_id in ascending order
  const mediaQuery = query(collection(db, collectionName), where('visible', '==', true));

  const querySnapshot = await getDocs(mediaQuery);
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
    // console.log('data', data);
  });``
  return data;
};

export const getAllPartnersDetails = async (lastDoc = null, pageSize = 200) => {
  let q = query(
    collection(db, USERS_DETAILS),
    where('role_id', '==', 10),
    where('isPartnerUserApproved', '==', false),
    limit(pageSize), // Limit the number of documents fetched
  );

  // If lastDoc exists, start the next query after it
  if (lastDoc) {
    q = query(q, startAfter(lastDoc), limit(pageSize));
  }

  const usersSnapshot = await getDocs(q);
  let userData = [];

  usersSnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    userData.push(newdata);
  });

  return { userData, lastDoc: usersSnapshot.docs[usersSnapshot.docs.length - 1] };
};

export const getAllApprovedPartnersDetails = async (lastDoc = null, pageSize = 200) => {
  let q = query(
    collection(db, USERS_DETAILS),
    where('role_id', '==', 10),
    where('isPartnerUserApproved', '==', true),
    limit(pageSize), // Limit the number of documents fetched
  );

  // If lastDoc exists, start the next query after it
  if (lastDoc) {
    q = query(q, startAfter(lastDoc), limit(pageSize));
  }

  const usersSnapshot = await getDocs(q);
  let userData = [];

  usersSnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    userData.push(newdata);
  });

  return { userData, lastDoc: usersSnapshot.docs[usersSnapshot.docs.length - 1] };
};

export const getPartnerEventDetails = async (userId) => {
  const eventSnapshot = await getDocs(
    query(collection(db, EVENTS), where('user_id', '==', userId)),
  );
  let eventData = [];
  eventSnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    eventData.push(newdata);
  });
  return eventData;
};

export const getMediaByEventIDAndMediaType = async (id, type, date) => {
  let querySnapshot;
  if (date) {
    querySnapshot = await getDocs(
      query(
        collection(db, EVENT_MEDIA),
        where('event_id', '==', id),
        where('event_media_type', '==', type),
        where('daily_event_date', '==', date),
      ),
    );
  } else {
    querySnapshot = await getDocs(
      query(
        collection(db, EVENT_MEDIA),
        where('event_id', '==', id),
        where('event_media_type', '==', type),
      ),
    );
  }
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getMediaByTestimonialIDAndMediaType = async (id, type) => {
  let querySnapshot;
  querySnapshot = await getDocs(
    query(
      collection(db, EVENT_MEDIA),
      where('testimonial_id', '==', id),
      where('event_media_type', '==', type),
    ),
  );
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getMediaBySbmIDAndMediaType = async (id, type) => {
  let querySnapshot;
  querySnapshot = await getDocs(
    query(
      collection(db, EVENT_MEDIA),
      where('sbm_id', '==', id),
      where('event_media_type', '==', type),
    ),
  );
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getEventThemeCountById = async (id) => {
  try {
    // Create a reference to the collection (replace 'events' with your collection name)
    const eventsRef = collection(db, 'events');

    // Create a query where theme id matches the provided id
    const q = query(eventsRef, where('event_theme_id', '==', id));

    // Get the count from the server
    const snapshot = await getCountFromServer(q);

    // Return the count
    return snapshot.data().count;
  } catch (error) {
    console.error('Error getting count from server:', error);
  }
};

export const getAllPartners = async () => {
  const querySnapshot = await getDocs(
    query(
      collection(db, USERS),
      where('role_id', '==', 10),
      where('isPartnerUserApproved', '==', true),
    ),
  );
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getTweetIDs = async () => {
  const querySnapshot = await getDocs(query(collection(db, TWEETS)));
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getJoinAdoptRequestById = async (userId, eventId) => {
  const querySnapshot = await getDocs(
    query(
      collection(db, 'join_adopt_ctu'),
      where('user_id', '==', userId),
      where('event_id', '==', eventId),
    ),
  );
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};

export const getInstaIDs = async () => {
  const querySnapshot = await getDocs(query(collection(db, INSTAGRAM)));
  let data = [];
  querySnapshot.forEach((doc) => {
    let newdata = doc.data();
    newdata.doc_id = doc.id;
    data.push(newdata);
  });
  return data;
};
