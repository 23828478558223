import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import NewBannerEnglish from '../../assets/newBanner_english.jpg';
import NewBannerHindi from '../../assets/newBanner_hindi.jpg';
import ModijiBannerEnglish from '../../assets/ModijiBanner_English.jpg';
import ModijiBannerHindi from '../../assets/ModiJiBanner_Hindi.jpg';

export default function LandingCarousel() {
  const { i18n } = useTranslation();
  const [banners, setBanners] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Determine banners based on language
  const getBanners = () => {
    if (i18n.language === 'en') {
      setBanners([ModijiBannerEnglish, NewBannerEnglish]);
    } else if (i18n.language === 'hi') {
      setBanners([ModijiBannerHindi, NewBannerHindi]);
    }
  };


  useEffect(() => {
    getBanners();
    setCurrentIndex(0); 
  }, [i18n.language]);


  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
    }, 7000); 

    return () => clearInterval(timer); 
  }, [banners]);

  return (
    <Carousel activeIndex={currentIndex} onSelect={() => {}}>
      {banners.map((banner, index) => (
        <Carousel.Item key={index}>
          <img src={banner} className="img-fluid" alt={`banner-${index}`} style={{ minWidth: '100vw' }} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
